<script>
import Cropper from 'cropperjs'

export default {
  name: 'Upload',
  components: {
    Action: () => import('@/components/general/Action'),
    Icon: () => import('@/components/general/Icon'),
    Modal: () => import('@/components/general/Modal'),
    ValidationMessage: () => import('@/components/general/ValidationMessage')
  },

  props: {
    label: {
      type: String,
      default: null
    },

    labelChange: {
      type: String,
      default: null
    },

    icon: {
      type: String,
      default: null
    },

    description: {
      type: String,
      default: null
    },

    cropper: {
      type: Boolean,
      default: false
    },

    width: {
      type: Number,
      default: 480
    },

    height: {
      type: Number,
      default: 160
    },

    accept: {
      type: String,
      default: null
    },

    preview: {
      type: Number,
      default: 1
    },

    outerLabel: {
      type: Boolean,
      default: false
    },

    validation: {
      type: Object,
      default: function () {
        return {}
      }
    },

    value: null,
    dark: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      modalActive: false,
      cropperWrapper: null,
      cropperConfig: {
        scaleX: 1,
        scaleY: 1
      },

      cropperImage: null,
      file: null,
      isFocused: false,
      aspectRatio: this.width / this.height
    }
  },

  computed: {
    hasValue () {
      return this.value !== null
    },

    hasValidation () {
      return Object.keys(this.validation).length
    },

    isRequired () {
      return this.validation.$params && typeof this.validation.$params.required === 'object'
    },

    style () {
      return {
        width: Math.round(this.width * this.preview) + 'px',
        height: Math.round(this.height * this.preview) + 'px'
      }
    }
  },

  methods: {
    getLabel () {
      return this.label ? this.label : this.cropper ? this.$t('global:upload.add.image') : this.$t('global:upload.add.file')
    },

    getLabelChange () {
      return this.labelChange ? this.labelChange : this.cropper ? this.$t('global:upload.change.image') : this.$t('global:upload.change.file')
    },

    getAccept () {
      return this.accept || this.cropper ? '.jpg,.jpeg,.png' : '*'
    },

    onChange (e) {
      this.file = e.target.files[0]
      e.target.type = ''
      e.target.type = 'file'
      this.openModal()
    },

    openModal () {
      this.$emit('open')
      this.modalActive = true

      this.$nextTick(() => {
        this.createImage()
        this.$refs.cropperModal.$el.focus()

        this.$refs.cropperModal.$el.addEventListener('keyup', (e) => {
          if (e.key === 'Enter') {
            this.save()
          }
        })
      })
    },

    createImage () {
      const self = this
      const reader = new window.FileReader()

      reader.onload = function (event) {
        if (self.$refs.cropperImage) self.$refs.cropperImage.src = event.target.result
        self.createCropper()
      }

      reader.readAsDataURL(this.file)
    },

    createCropper () {
      this.cropperWrapper = new Cropper(this.$refs.cropperImage, {
        aspectRatio: this.aspectRatio,
        guides: false,
        center: true,
        highlight: false
      })
    },

    swapHorizontal () {
      this.cropperConfig.scaleX = this.cropperConfig.scaleX * -1
      this.cropperWrapper.scale(this.cropperConfig.scaleX, this.cropperConfig.scaleY)
    },

    swapVertical () {
      this.cropperConfig.scaleY = this.cropperConfig.scaleY * -1
      this.cropperWrapper.scale(this.cropperConfig.scaleX, this.cropperConfig.scaleY)
    },

    rotateLeft () {
      this.cropperWrapper.rotate(-45)
    },

    rotateRight () {
      this.cropperWrapper.rotate(45)
    },

    restore () {
      this.cropperConfig.scaleX = 1
      this.cropperConfig.scaleY = 1
      this.cropperWrapper.reset()
    },

    save () {
      this.cropperImage = this.cropperWrapper.getCroppedCanvas({
        maxWidth: 3072,
        maxHeight: 3072
      }).toDataURL()

      this.modalActive = false
      this.$refs.input.focus()
      const canvas = this.cropperWrapper.getCroppedCanvas({
        maxWidth: 3072,
        maxHeight: 3072
      })

      if (HTMLCanvasElement.prototype.toBlob) {
        canvas.toBlob(this.updateValue, 'image/jpeg', 0.95)
      }

      if (HTMLCanvasElement.prototype.msToBlob) {
        this.updateValue(canvas.msToBlob())
      }
    },

    updateValue (value) {
      (typeof this.validation.$touch === 'function') && this.validation.$touch()
      value.name = Math.random() + this.file.name
      this.$emit('input', value)
    },

    closeModal () {
      this.file = null
      // this.cropperWrapper = null
      this.modalActive = false
    },

    updateFocus () {
      this.isFocused = true
    },

    updateBlur () {
      this.isFocused = false
    }
  }
}
</script>

<template>
  <div
    class="form-item"
    :class="{ 'has-error': validation.$error, 'has-focus': isFocused, 'has-value': hasValue, 'theme-dark': dark, 'is-disabled': disabled }"
  >
    <p
      v-if="description"
      class="upload-description"
    >
      {{ description }} <span v-if="!isRequired">{{ $t('global:form.optional') }}</span>
    </p>
    <div
      class="uploader"
      :style="style"
    >
      <label
        :for="'input-' + _uid"
        class="uploader-label"
      >
        <div
          v-if="!cropperImage && value === null && !outerLabel"
          class="uploader-label-content"
        >
          <Icon
            v-if="icon"
            :name="icon"
            class="uploader-label-icon"
            wrapper
            medium
          />
          <span
            v-if="label"
            :class="{'outer-label-text': outerLabel}"
            class="uploader-label-text"
          >{{ getLabel() }}</span>
        </div>
        <slot
          v-if="(cropperImage || value) && !outerLabel"
          name="change"
        >
          <span class="uploader-label-change">{{ getLabelChange() }}</span>
        </slot>
        <div
          class="uploader-canvas-container"
        >
          <img
            v-if="value || cropperImage"
            :src="cropperImage ? cropperImage : value"
          >
          <slot
            v-else
            name="image"
          />
        </div>
        <slot name="mask" />
      </label>
      <input
        :id="'input-' + _uid"
        ref="input"
        type="file"
        class="uploader-file"
        :disabled="disabled"
        :accept="getAccept()"
        @change="onChange"
        @focus="updateFocus()"
        @blur="updateBlur()"
      >
    </div>

    <label
      v-if="outerLabel"
      :for="'input-' + _uid"
      class="uploader-label"
    >
      <div
        v-if="!cropperImage && value === null"
        class="uploader-label-content"
      >
        <Icon
          v-if="icon"
          :name="icon"
          class="uploader-label-icon"
          medium
          wrapper
        />
        <span
          v-if="label"
          :class="{'outer-label': outerLabel}"
          class="uploader-label-text"
        >{{ getLabel() }}</span>
      </div>
      <slot
        v-if="cropperImage || value"
        name="change"
      >
        <span
          :class="{'outer-label': outerLabel}"
          class="uploader-label-change"
        >{{ getLabelChange() }}</span>
      </slot>
      <slot name="mask" />
    </label>

    <div
      v-if="hasValidation"
      class="form-input-details"
    >
      <ValidationMessage :validation="validation" />
    </div>

    <Modal
      ref="cropperModal"
      :active="modalActive && cropper"
      :close-event="true"
      @close="closeModal"
    >
      <div class="cropper theme-dark">
        <div class="cropper-tools">
          <Action
            type="button"
            icon="swap-horizontal"
            wrapper
            @click="swapHorizontal"
          />
          <Action
            type="button"
            icon="swap-vertical"
            wrapper
            @click="swapVertical"
          />
          <Action
            type="button"
            icon="undo"
            wrapper
            @click="rotateLeft"
          />
          <Action
            type="button"
            icon="redo"
            wrapper
            @click="rotateRight"
          />
          <Action
            type="button"
            icon="restore"
            wrapper
            @click="restore"
          />
        </div>
        <div class="cropper-content">
          <div class="cropper-image-container">
            <img
              id="cropper-image"
              ref="cropperImage"
              src=""
            >
          </div>
          <Action
            type="button"
            :text="$t('global:form.save')"
            primary
            dark
            class="cropper-btn"
            @click="save"
          />
        </div>
      </div>
    </Modal>
  </div>
</template>

<style src="@/assets/styles/themes/default/upload.css"></style>
